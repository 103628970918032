type EnvVariables = {
  REACT_APP_REGION: string
  REACT_APP_AWS_COGNITO_REGION: string
  REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID: string
  REACT_APP_EXTENSION_URL: string
  REACT_APP_SUPPORT_URL: string
  REACT_APP_VAULT_URL: string
}

export const getEnv = (): EnvVariables => {
  return {
    REACT_APP_REGION: process.env.REACT_APP_REGION || '',
    REACT_APP_AWS_COGNITO_REGION: process.env.REACT_APP_AWS_COGNITO_REGION || '',
    REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || '',
    REACT_APP_EXTENSION_URL: process.env.REACT_APP_EXTENSION_URL || '',
    REACT_APP_SUPPORT_URL: process.env.REACT_APP_SUPPORT_URL || '',
    REACT_APP_VAULT_URL: process.env.REACT_APP_VAULT_URL || '',
  }
}
