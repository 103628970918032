import { SetupCard, SetupCardButton, Typography } from '@affinidi/component-library'
import { ReactNode } from 'react'
import * as S from './CustomErrorView.styled'
import { getEnv } from '../../env'
import { EVENTS, postMessageToParent } from '../../features/windowMessaging'

const env = getEnv()

type Props = {
  children: ReactNode
  onRetry: VoidFunction
}

const onRetry = () => {
  postMessageToParent(EVENTS.RETRY)
}

const CustomErrorView = ({ children }: Props) => {
  return (
    <S.CardOverlay>
      <SetupCard title="Error">
        <Typography variant="p2" tag="div">
          {children}

          <S.LinkWrapper>
            Please try again or raise a{' '}
            <a href={env.REACT_APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
              Support Ticket.
            </a>
          </S.LinkWrapper>
        </Typography>
        <SetupCardButton onClick={onRetry}>Try again</SetupCardButton>
      </SetupCard>
    </S.CardOverlay>
  )
}

export default CustomErrorView
