const isNewerIpad = () => {
  // iPads on iOS13+ return as if a desktop Mac
  // so check for maxTouchPoints also.
  const TOUCH_POINT_THRESHOLD = 1
  return (
    /Macintosh/i.test(navigator.userAgent) &&
    !!navigator.maxTouchPoints &&
    navigator.maxTouchPoints > TOUCH_POINT_THRESHOLD
  )
}
const isMobileScreen = () => {
  const isMobileDevice = /Android|iPhone|iPad/i.test(navigator.userAgent) || isNewerIpad()
  return isMobileDevice
}
/**
 * Use window.matchMedia to direct landscape orientation
 * screen.orientation is not supported in Safari so we will use
 * media query detection to listen for changes instead.
 * @returns MediaQueryList object
 */
const getLandscapeMediaQuery = () => {
  return window.matchMedia('(orientation: landscape)')
}

export { getLandscapeMediaQuery, isMobileScreen }
