import { theme } from '@affinidi/component-library'
import { View, Flex } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { useLayoutEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import FaceLiveness from './Components/FaceLiveness'
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'
import './App.css'
import './amplify-custom.css'
import { getEnv } from './env'

const env = getEnv()

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      allowGuestAccess: true,
    },
  },
})

const App = () => {
  useLayoutEffect(() => {
    document.documentElement.style.fontSize = '8px'
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        fontSize={16}
      >
        <View as="div" maxHeight="600px" height="600px" width="640px" maxWidth="640px">
          <FaceLiveness />
        </View>
      </Flex>
    </ThemeProvider>
  )
}

export default App
