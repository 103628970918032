import styled from 'styled-components'

export const LinkWrapper = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #cdced3;

  & a {
    color: #1d58fc;
  }
`

export const CardOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(54, 57, 78, 0.5);
  position: absolute;
  top: 0;
  left: 0;
`
